//========= BREAK POINTS ===========//
@mixin desktop {
  @media all and (min-width: 1440px) {
    @content;
  }
}

@mixin onlyMobile($displayType: block) {
  display: $displayType;
  @content;

  @include desktop {
    display: none;
  }
}


@mixin onlyDesktop($displayType: block) {
  display: none;

  @include desktop {
    display: $displayType;
    @content;
  }
}

//========= FONTS ===========//
@mixin TitanOne {
  font-family: "Titan One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin PocketCalculator {
  font-family: "Pocket Calculator", sans-serif;
  font-weight: 400;
  font-style: normal;
}

//========= CONTAINERS ===========//
@mixin container {
  padding-left: 24px;
  padding-right: 24px;

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin inner {
  @include desktop {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

//========= OTHER ===========//
@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin card {

}
