@import "../../../assets/style/app";

.transactionInfo {
  @include container;
  padding-top: 24px;


  .inner {
    @include inner;


    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
    }

  }
}