@import "../../../assets/style/app";

.howToBuyMobile {
  @include onlyMobile();
  overflow: hidden;
  padding-top: 64px;
  padding-bottom: 8px;

  .titleWrapper {
    @include centerRow;

    .title {
      position: relative;

      .coinLeft {
        position: absolute;
        z-index: 1;
        width: 99px;
        height: auto;
        left: -64px;
        top: -60px;
      }

      .coinRight {
        position: absolute;
        z-index: 3;
        width: 147px;
        right: -88px;
        top: 0;
      }

      span {
        position: relative;
        z-index: 2;
        @include TitanOne;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        text-transform: uppercase;
        color: #000;
      }

    }
  }

  .cards {
    margin-top: 32px;
    padding: 0 24px;

    .card {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

}


.howToBuyDesktop {
  @include onlyDesktop;
  overflow: hidden;
  position: relative;
  width: 100%;

  .back {
    @include centerRow;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    //background: lightgreen;
    box-sizing: border-box;
    //border: 20px solid green;

    .titleWrapper {
      position: relative;

      .title {
        position: relative;
        z-index: 2;
        @include TitanOne;
        font-weight: 400;
        font-size: 184px;
        line-height: 110%;
        text-transform: uppercase;
        text-align: center;
        color: #000;
      }

      .coinLeft {
        position: absolute;
        z-index: 1;
        width: 355px;
        height: 489px;
        top: -327px;
        left: -98px;
      }

      .coinRight {
        position: absolute;
        z-index: 3;
        width: 418px;
        height: 353px;
        bottom: -266px;
        right: -137px;
      }

      .starLeft {
        position: absolute;
        z-index: 3;
        width: 116px;
        height: 141px;
        left: 200px;
        bottom: -35px;
      }

      .starRight {
        position: absolute;
        z-index: 3;
        width: 321px;
        height: 396px;
        top: -146px;
        right: -148px;
      }
    }
  }

  .screen {
    @include centerRow;
    position: relative;
    z-index: 2;
    height: 100vh;
    //border: 5px solid blue;
    box-sizing: border-box;

    .rotateLeft {
      transform: rotate(15deg);
    }

    .rotateRight {
      transform: rotate(-15deg);
    }
  }
}