@import "../../../assets/style/app";

.runningString {
  position: relative;
  height: 38px;
  background-color: #EF87CC;
  border-top: 1px solid #000;
  border-bottom: 3px solid #000;
  overflow: hidden;

  .part {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .item {
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      white-space: nowrap;

      &:nth-child(2n) {
        font-style: normal;
        margin: 0 40px;
      }
    }

  }
}