@import "../../../assets/style/app";

.items {
  margin-top: 24px;

  .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
  }

  .value {
    @include TitanOne;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000;
    margin-top: 8px;
    white-space: nowrap;
  }

  .itemsMobile {
    @include onlyMobile;
    overflow: hidden;
    position: relative;
    background: #f2c53b;
    border-top: 1px solid #000;
    border-bottom: 3px solid #000;
    height: 120px;

    .part {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;

      .section {
        display: flex;
        align-items: center;

        .sectionItem {
          position: relative;
          padding-left: 24px;
          padding-right: 24px;

          &::after {
            display: block;
            content: "";
            width: 1px;
            height: 43px;
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }
      }

    }
  }

  .itemsDesktop {
    @include onlyDesktop;
    width: 100%;
    height: 100%;

    .inner {
      @include inner;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border: 1px solid #000;
      border-radius: 8px;
      box-shadow: 0 3px 0 0 #000;
      padding: 24px;
      box-sizing: border-box;
      background: #f2c53b;

      .sectionItem {
        position: relative;

        &:not(:first-child) {
          padding-left: 24px;
        }

        &:not(:last-child) {
          &::after {
            display: block;
            content: "";
            width: 1px;
            height: 43px;
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }

      }
    }

  }

}