@import "../../assets/style/app";

.burgerMenu {
  @include onlyMobile(flex);
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 130px 24px 24px;
  background-color: #FFF;
  transition: 0.3s;
  transform: translateX(101%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_open {
    transform: translateX(0%);
  }

  .links {
    position: relative;
    z-index: 2;

    .link {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

  }

  .socialLinks {
    position: relative;
    z-index: 2;

    .design {
      margin-top: 24px;
      font-style: italic;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }

    .rights {
      margin-top: 28px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }
  }

  .borderRight {
    position: absolute;
    bottom: 0;
    right: 22px;
    height: 400px;
  }

  .star {
    position: absolute;
    z-index: 1;
    bottom: 188px;
    left: 0;
    height: 225px;
  }


}