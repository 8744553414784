@import "../../../../assets/style/app";

.totalStaked {
  overflow: hidden;
  border: 1px solid #000;
  border-radius: 16px;
  box-shadow: 0 8px 0 0 #000;
  background: #FFF;
  padding: 24px 0;
  margin-top: 24px;

  @include desktop {
    margin-top: 0;
  }

  .title {
    @include TitanOne;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000;
    margin-left: 24px;

    @include desktop {
      font-size: 32px;
    }
  }

  .graphMobile {
    @include onlyMobile();
    position: relative;
    margin-top: 24px;
    height: 329px;

    .part {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;

      svg {
        width: 473px;
        height: 329px;
        margin-left: 24px;
      }
    }
  }

  .graphDesktop {
    @include onlyDesktop;
    margin-top: 24px;

    svg {
      width: 544px;
      height: 384px;
      margin-left: 24px;
    }
  }

}