@import "../../../assets/style/app";

.forms {
  @include container;
  padding-top: 24px;

  .inner {
    @include inner;
    @include desktop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
    }

    .card {
      border: 1px solid #000;
      border-radius: 16px;
      box-shadow: 0 8px 0 0 #000;
      background: #FFF;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 510px;
      }

      &:not(:first-child) {
        margin-top: 24px;

        @include desktop {
          margin-top: 0;
        }
      }

      .icon {
        @include centerRow;
        width: 64px;
        height: 64px;
        border: 1px solid #000;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;

        @include desktop {
          width: 80px;
          height: 80px;
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
        }

      }

      .title {
        @include TitanOne;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        text-transform: uppercase;
        color: #000;
        text-align: center;
        margin-top: 16px;

        @include desktop {
          font-size: 32px;
          margin-top: 24px;
        }
      }

      .description {
        margin-top: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #11121c;
      }

      .infoField {
        margin-top: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 0 16px;


        .label {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: rgba(0, 0, 0, 0.5);
        }

        .value {
          flex-shrink: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #11121c;
        }
      }

      .inputField {
        margin-top: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        height: 60px;
        padding-left: 8px;
        padding-right: 4px;
        display: flex;
        align-items: center;

        .coin {
          @include onlyDesktop(flex);
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          width: 44px;
          height: 44px;
          background: #eff0f4;
          flex-shrink: 0;
        }

        input {
          flex-grow: 1;
          margin-right: 16px;
          background: none;
          border: none;
          outline: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #000;
          width: 100%;

          @include desktop {
            margin-left: 16px;
          }

          /* Chrome, Safari, Edge, Opera */
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type=number] {
            -moz-appearance: textfield;
          }
        }

        .max {
          @include button;
          @include centerRow;
          border: 1px solid #000;
          border-radius: 8px;
          width: 87px;
          height: 52px;
          background: #c599e0;
          flex-shrink: 0;

          @include desktop {
            width: 103px;
          }

          span {
            font-style: italic;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            text-transform: uppercase;
            text-align: center;
            color: #000;
          }
        }

      }

      .submitBtn {
        @include button;
        @include centerRow;
        margin-top: 16px;
        border: 1px solid #000;
        border-radius: 8px;
        height: 60px;
        box-shadow: 0 2px 0 0 #000;
        background: #f77113;
        transition: 0.3s;

        @include desktop {
          margin-top: 0;
        }

        &:hover {
          background: #f68c43;
        }

        span {
          font-style: italic;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          text-transform: uppercase;
          text-align: center;
          color: #000;
        }

      }

      .form {
        flex-grow: 1;
        //background: red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

    }
  }


}