@import "../../../assets/style/app";

.socialLinks {

  .link {
    text-decoration: none;
    display: block;
    border: 1px solid #000;
    padding: 2px;
    border-radius: 9px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .inner {
      border-radius: 7px;
      background-color: #000;
      display: flex;
      align-items: center;
      padding: 16px;
      transition: background-color 0.3s;

      svg {
        width: 24px;
        height: 24px;

        path {
          transition: 0.3s;
        }
      }

      p {
        font-style: italic;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
        color: #f2c53b;
        margin-left: 12px;
        transition: color 0.3s;
      }

    }

    &:hover {
      @include desktop {

        .inner {
          background-color: #ef87cc;

          svg {
            path {
              fill: #000;
            }

          }

          p {
            color: #000;
          }
        }


      }
    }
  }
}