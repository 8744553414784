@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #000;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Pocket Calculator";
  font-weight: 400;
  font-style: normal;
  src: local("Pocket Calculator"), url(./assets/fonts/pocket_calcuatlor_ot.otf) format("truetype");
}
